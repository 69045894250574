/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import SmsIcon from "@material-ui/icons/Sms";
import BrushIcon from "@material-ui/icons/Brush";
import DescriptionIcon from "@material-ui/icons/Description";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import MovieIcon from "@material-ui/icons/Movie";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EventIcon from "@material-ui/icons/Event";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import cardProject2 from "assets/img/texting.jpg";
import cardProject5 from "assets/img/film-production.jpg";

const useStyles = makeStyles(projectsStyle);
const useStyles2 = makeStyles({
  pluginImage: {
    "border-radius": "5px",
    width: "100%",
  },
});

export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.projects + " " + classes.projects4}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Enhance Your Workflow with Plugins
              </h2>
              <div className={classes.sectionSpace} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <img
                src={cardProject2}
                alt="..."
                className={classes2.pluginImage}
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <InfoArea
                className={classes.info4}
                title="ON-Message"
                description="Notifications via e-mail, SMS, and more."
                icon={SmsIcon}
                iconColor="danger"
              />
              <InfoArea
                className={classes.info4}
                title="ON-Air Graphics"
                description="Creation of secondary events and promo projects."
                icon={BrushIcon}
                iconColor="danger"
              />
              <InfoArea
                className={classes.info4}
                title="ON-Script"
                description="Script tracking and searching in each creative."
                icon={DescriptionIcon}
                iconColor="danger"
              />
              <InfoArea
                className={classes.info4}
                title="ON-Spot"
                description="Linking of video assets to spot inventory with approval tracking."
                icon={SubscriptionsIcon}
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <InfoArea
                className={classes.info4}
                title="ON-Slate"
                description="Automated slate creation from existing spot info."
                icon={MovieIcon}
                iconColor="danger"
              />
              <InfoArea
                className={classes.info4}
                title="ON-Request"
                description="Marketing request forms and processes."
                icon={FormatAlignJustifyIcon}
                iconColor="danger"
              />
              <InfoArea
                className={classes.info4}
                title="ON-Budget"
                description="Budgeting, cost tracking and financial reporting."
                icon={MonetizationOnIcon}
                iconColor="danger"
              />
              <InfoArea
                className={classes.info4}
                title="ON-Air Scheduling"
                description="Program scheduling and promotion orders from schedule."
                icon={EventIcon}
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <img
                src={cardProject5}
                alt="..."
                className={classes2.pluginImage}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
